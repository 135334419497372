import React from 'react'
import SbEditable from 'storyblok-react'
import {
  getFluidGatsbyImage,
  getFixedGatsbyImage,
} from 'gatsby-storyblok-image'
import { Img } from '@system'
import convertIntValues from '@helpers/convert-int-values'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  isRetinaBrowser,
  fileIsRetina,
  calculateDimension,
} from '@helpers/images'
import ACC_THEME from '@themes'

const Image = (props) => {
  const {
    image,
    style,
    title,
    altText,
    width,
    height,
    maxHeight,
    maxWidth,
    minWidth,
    minHeight,
    mobileImage,
    objectFit,
    objectPosition,
    tabletImage,
    src,
    ...otherProps
  } = props.blok

  const { thumbnailHeight, cardModuleMaxWidth } = props
  const tablet = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const mobile = useMediaQuery(ACC_THEME.breakpoints.down('xs'))
  const imageAsset =
    mobile && mobileImage?.filename
      ? mobileImage.filename
      : tablet && tabletImage?.filename
      ? tabletImage.filename
      : image.image || image

  const imageSrc =
    !!imageAsset && imageAsset.split('/')[imageAsset.split('/').length - 1]
  const imageAltText =
    !!imageSrc && imageSrc.substr(0, imageSrc.lastIndexOf('.'))
  const imageType =
    !!imageSrc && imageSrc.slice(((imageSrc.lastIndexOf('.') - 1) >>> 0) + 2)

  if (!imageType) {
    return (
      <Box maxWidth={maxWidth || '600px'} maxHeight={maxHeight || '100%'}>
        <img
          src={imageAsset}
          title={title}
          alt={altText || imageAltText}
          style={{
            maxWidth: '100%',
            display: 'block',
            margin: 'auto',
          }}
        />
      </Box>
    )
  }

  const isSVG = imageAsset.indexOf('.svg') === imageAsset.length - 4
  const isGIF = imageAsset.indexOf('.gif') === imageAsset.length - 4
  const isMP4 = imageAsset.indexOf('.mp4') === imageAsset.length - 4

  const isFixed = !!thumbnailHeight
  const fileIsRetinaBool = fileIsRetina(imageAsset)

  const prepareImageUrl = (path) =>
    path.replace('https:', '').replace('http:', '')

  const preparedImage = prepareImageUrl(imageAsset)

  const fluidProps = getFluidGatsbyImage(preparedImage, {
    maxWidth: calculateDimension({
      value: maxWidth,
      _fileIsRetina: fileIsRetinaBool,
      _isRetinaBrowser: isRetinaBrowser,
      imageAsset,
    }),
  })

  const fixedProps = getFixedGatsbyImage(preparedImage, {
    width: calculateDimension({
      value: width || maxWidth || cardModuleMaxWidth,
      _fileIsRetina: fileIsRetinaBool,
      _isRetinaBrowser: isRetinaBrowser,
      imageAsset,
    }),
    height: calculateDimension({
      value: height || thumbnailHeight,
      _fileIsRetina: fileIsRetinaBool,
      _isRetinaBrowser: isRetinaBrowser,
      imageAsset,
    }),
  })

  const styleProps = convertIntValues({
    width: calculateDimension({
      value: width || cardModuleMaxWidth || '100%',
      _fileIsRetina: fileIsRetinaBool,
      _isRetinaBrowser: isRetinaBrowser,
      imageAsset,
    }),
    height: calculateDimension({
      value: height || (!!thumbnailHeight && thumbnailHeight),
      _fileIsRetina: fileIsRetinaBool,
      _isRetinaBrowser: isRetinaBrowser,
      imageAsset,
    }),
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    objectFit,
    objectPosition,
  })

  return (
    <SbEditable content={props.blok}>
      {isSVG ? (
        <Img
          isSVG={isSVG}
          src={preparedImage}
          alt={altText || imageAltText || title}
          style={{
            ...props.style,
            ...styleProps,
          }}
          {...otherProps}
        />
      ) : isGIF ? (
        <img
          src={preparedImage}
          alt={altText || imageAltText || title}
          style={{
            ...props.style,
            ...styleProps,
          }}
          {...otherProps}
        />
      ) : isMP4 ? (
        <video
          autoPlay
          muted
          playsInline
          src={preparedImage}
          alt={altText || imageAltText || title}
          style={{
            ...props.style,
            ...styleProps,
          }}
        />
      ) : isFixed ? (
        <Img
          isSVG={isSVG}
          src={preparedImage}
          fixed={fixedProps}
          title={title}
          alt={altText || imageAltText}
          style={{
            ...props.style,
            ...styleProps,
          }}
          {...otherProps}
        />
      ) : (
        <Img
          isSVG={isSVG}
          src={preparedImage}
          fluid={fluidProps}
          title={title}
          alt={altText || imageAltText}
          style={{
            ...props.style,
            ...styleProps,
          }}
          {...otherProps}
        />
      )}
    </SbEditable>
  )
}

export default Image
