const convertIntValues = object =>
  Object.entries(object).reduce(
    (acc, entry) => ({
      ...acc,
      [entry[1] !== '' && entry[0]]: entry[1] || null,
    }),
    {}
  )

export default convertIntValues
